import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { dirsLayerActions } from './actions';
import { useCallback } from 'react';
import { FetchFieldValuesPayload, Field, UpdateFieldValuesPayload } from '.';
import { Dir } from '../dirs';
import { selectDirFields } from './selectors';
import { RootState } from '../../store';
import http from '../../utils/http';

export function useDirIntelligentLayerActions() {
  const dispatch = useDispatch();
  const actions = dirsLayerActions;

  const fetch = useCallback(
    (params: FetchFieldValuesPayload) =>
      dispatch(actions.fetch.trigger(params)),
    // eslint-disable-next-line
    [dispatch]
  );

  const update = useCallback(
    (params: UpdateFieldValuesPayload) =>
      dispatch(actions.update.trigger(params)),
    // eslint-disable-next-line
    [dispatch]
  );

  return {
    fetch,
    update
  };
}

export function useDirIntelligenceFields(id: Dir['id']) {
  return useSelector(
    (state: RootState) => selectDirFields(state, id),
    shallowEqual
  );
}

export function useIntelligenceFields(groups: string[]) {
  const key = groups.join('_');

  return useQuery(`app_intelligence_fields_groups-${key}`, () => {
    return (http.get('/api/intelligence/group', {
      params: { groups }
    }) as unknown) as Field[];
  });
}

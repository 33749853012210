import React from 'react';
import { DefaultField } from './DefaultField';
import { FieldProps } from './types';
import { DateField } from './DateField';
import { SliderField } from './Slider';
import { CheckboxField } from './CheckboxField';
import { LongTextField } from './LongTextField';
import { SelectField } from './SelectField';
import { SelectCreatable } from './SelectCreatable';
import { RadioGroupField } from './RadioGroupField';
import { AutocompleteField } from './AutocompleteField';

type FieldsMap = {
  [key: string]: any;
};

const FormField: React.FC<FieldProps> = props => {
  const fieldsMap: FieldsMap = {
    'radio-group': RadioGroupField,
    select: SelectField,
    'select-creatable': SelectCreatable,
    autocomplete: AutocompleteField,
    longtext: LongTextField,
    boolean: CheckboxField,
    range: SliderField,
    date: DateField
  };

  if (props.type in fieldsMap) {
    const Component = fieldsMap[props.type] as any;

    return <Component {...props} />;
  }

  return <DefaultField {...props} />;
};

export { FormField };

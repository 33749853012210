import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'utils/translation';
import { Button, CardActions, Typography, colors } from '@material-ui/core';
import { getTreeFromFlatData } from 'react-sortable-tree';
import { Loader, TreeSelect } from '../../../../../components';
import { Dir } from '../../../../../modules/dirs';
import { useQuery } from 'react-query';
import http from '../../../../../utils/http';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  divider: {
    height: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end',
    paddingRight: 0
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

interface DirView {
  id: Dir['id'];
  title: string;
  parentId: Dir['id'] | null;
  path: Dir['id'][];
}

interface MoveFormProps {
  onCancel: () => void;
  onSubmit: (dirId: number) => void;
  title: string;
  filter?(dir: DirView): boolean;
}

export const MoveForm: React.FC<MoveFormProps> = ({
  onCancel,
  onSubmit,
  title,
  filter
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const [dir, setDir] = React.useState<Nullable<Dir>>(null);

  const { data: dirs = [], isLoading } = useQuery<DirView[]>(
    'move_modal_dirs',
    () => {
      return http.get<never, DirView[]>('/api/directory/preload');
    },
    {
      refetchInterval: false
    }
  );

  const treeData = React.useMemo(() => {
    let flatData = dirs || [];

    if (filter) {
      flatData = flatData.filter(filter);
    }

    flatData = flatData.map(node => ({
      ...node,
      value: node.id,
      key: node.id,
      label: node.title,
      checked: dir && dir.id === node.id
    }));

    const data = getTreeFromFlatData({
      // @ts-ignore
      flatData: flatData,
      getParentKey: node => {
        // @ts-ignore
        return node.parentId || '0';
      }
    });

    data.unshift({
      id: 0,
      label: t('My Disk'),
      checked: dir && dir.id === 0
    });

    return data;
  }, [dirs, dir, filter, t]);

  const handleSubmit = () => {
    if (dir) {
      onSubmit(dir.id);
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSelectedDirChange = (dir: Dir | any, nodes: any[]) => {
    if (nodes.length) {
      setDir(dir as Dir);
    } else {
      setDir(null);
    }
  };

  return (
    <div className={classes.root}>
      <Typography align="center" gutterBottom variant="h3">
        {title}
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <TreeSelect
          className="directory-selection"
          data={treeData}
          onChange={handleSelectedDirChange}
          texts={{
            placeholder: t('Select directory')
          }}
        />
      )}
      <div className={classes.divider} />
      <CardActions className={classes.actions}>
        <Button variant="contained" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={!dir}
          type="submit"
          className={classes.saveButton}
          onClick={handleSubmit}>
          {t('Move')}
        </Button>
      </CardActions>
    </div>
  );
};

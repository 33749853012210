import React, { useEffect } from 'react';
import { Dir } from 'modules/dirs';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableFooter,
  Typography,
  Hidden,
  colors,
  useMediaQuery
} from '@material-ui/core';
import { Loader } from 'components';
import { Theme } from '@material-ui/core';
import get from 'lodash/get';
import {
  useDirIntelligentLayerActions,
  useDirIntelligenceFields
} from 'modules/intelligent-layer/hooks';
import { Formik, Form, Field, FieldArray } from 'formik';
import { useTranslation } from 'utils/translation';
import Button from '@material-ui/core/Button';
import {
  FieldUpdatePayload,
  Field as FieldType
} from '../../../../../modules/intelligent-layer';
import { FormField } from './FormFields/FormField';
import { Alert } from '../../../../../components';

interface Props {
  dir: Dir;
  onCancel(): void;
  onSubmitError(error?: any): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2, 0)
  },
  alert: {
    marginBottom: theme.spacing(1)
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

interface FormValues {
  fields: FieldType[];
}

const useFieldValidator = (field: FieldType) => {
  const { t } = useTranslation('myDisk');
  const { settings = {} } = field;

  return (value: string) => {
    if (settings.required && !value) {
      return t('Required');
    }
  };
};

const FieldWrapper = (props: any) => {
  return (
    <FormField
      errorMessage={props.error}
      form={props.form}
      type={props.fieldType}
      settings={props.settings}
      name={props.field.name}
      fieldName={props.fieldName}
      multiple={props.multiple}
      value={props.field.value}
      onChange={props.field.onChange}
    />
  );
};

interface RowProps {
  field: FieldType;
  name: string;
  error: string | null;
}

const FormFieldRow: React.FC<RowProps> = ({ field, name, error }) => {
  const validator = useFieldValidator(field);
  const isMdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up('md'));

  const label = (
    <Typography>{get(field, 'settings.label', field.name)}</Typography>
  );

  const formField = (
    <Field
      error={error}
      validate={validator}
      fieldType={field.type}
      fieldName={field.name}
      multiple={field.multiple}
      settings={field.settings}
      name={name}
      component={FieldWrapper}
    />
  );

  if (isMdUp) {
    return (
      <TableRow>
        <TableCell>{label}</TableCell>
        <TableCell>{formField}</TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell colSpan={2}>
        <div>{label}</div>
        <div>{formField}</div>
      </TableCell>
    </TableRow>
  );
};

const IntelligentLayerValues: React.FC<Props> = ({
  dir,
  onCancel,
  onSubmitError
}) => {
  const classes = useStyles();
  const { t } = useTranslation('myDisk');
  const { fetch, update } = useDirIntelligentLayerActions();
  const {
    isFetching,
    isSubmitting,
    fields,
    error,
    didUpdate
  } = useDirIntelligenceFields(dir.id);

  useEffect(() => {
    fetch({
      id: dir.id
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (didUpdate) {
      onCancel();
    }
    // eslint-disable-next-line
  }, [didUpdate]);

  useEffect(() => {
    if (error) {
      onSubmitError(error);
    }
    // eslint-disable-next-line
  }, [error]);

  const resolveValue = (value: object | string | any) => {
    if (value && typeof value === 'object' && 'value' in value) {
      return value.value;
    }

    return value;
  };

  const handleSubmit = ({ fields }: FormValues) => {
    const payload: FieldUpdatePayload = {};

    fields.forEach(field => {
      payload[field.name] = resolveValue(field.value);
    });

    update({
      dirId: dir.id,
      fields: payload
    });
  };

  const hasFields = Array.from(fields || []).length > 0;

  if (isFetching) {
    return <Loader />;
  }

  if (!hasFields) {
    return (
      <Typography variant="subtitle1" align="center">
        {t('This directory does not have any fields yet')}
      </Typography>
    );
  }

  return (
    <div className={classes.root}>
      {error && (
        <Alert className={classes.alert} variant="error" message={error} />
      )}
      <Formik
        initialValues={{ fields }}
        onSubmit={handleSubmit}
        render={({ values, errors }) => (
          <Form>
            <Table>
              <Hidden mdDown>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('Field')}</TableCell>
                    <TableCell>{t('Value')}</TableCell>
                  </TableRow>
                </TableHead>
              </Hidden>
              <TableBody>
                <FieldArray
                  name="fields"
                  render={() =>
                    values.fields.map((field, index) => (
                      <FormFieldRow
                        key={field.id}
                        field={field}
                        name={`fields.${index}.value`}
                        error={get(errors, `fields.${index}.value`)}
                      />
                    ))
                  }
                />
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell>
                    <Button
                      onClick={onCancel}
                      type="button"
                      variant="contained">
                      {t('Cancel')}
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      className={classes.saveButton}
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}>
                      {t('Save')}
                    </Button>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Form>
        )}
      />
    </div>
  );
};

export { IntelligentLayerValues };

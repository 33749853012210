import React from 'react';
import { FieldProps } from './types';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { applyFilters } from '../../../../../../core';

const SelectField: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  fieldName,
  multiple,
  onChange,
  disabled,
  form,
  settings = {}
}) => {
  const options = applyFilters(
    `intelligence_field_options_${fieldName}`,
    Array.from(settings.options || []),
    { form }
  ) as any[];
  const defaultValue = multiple ? [] : '';

  const resolveValue = () => {
    if (value && typeof value === 'object' && 'value' in value) {
      return value.value;
    }

    return value || defaultValue;
  };

  const fieldValue = resolveValue();

  React.useEffect(() => {
    if (
      fieldValue &&
      !options.find(item => item.value.toString() === fieldValue.toString())
    ) {
      console.log('Value not found in options... ', fieldValue, options);
      form.setFieldValue(name, null, false);
    }
  }, [form, name, options, fieldValue]);

  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <Select
        disabled={disabled || options.length === 0}
        multiple={multiple}
        name={name}
        value={fieldValue}
        onChange={onChange}>
        {options.map((option, index) => (
          <MenuItem disabled={option.disabled} key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export { SelectField };

import {
  DirResource,
  FileResource,
  BaseResource,
  RESOURCE_TYPE,
  Resource
} from './index';
import { Dir } from '../dirs';
import { File } from '../files';

export function isDir(resource: BaseResource): resource is DirResource {
  return resource && resource.type === RESOURCE_TYPE.DIRECTORY;
}

export function isInheritedDir(resource: BaseResource) {
  if (!isDir(resource)) {
    return false;
  }

  return resource.directoryType === 'inherited-type';
}

export function isObjectTypeDir(resource: Dir) {
  return resource.directoryType === 'object-type';
}

export function isUniqueObjectDir(resource: Dir) {
  return resource.directoryType === 'unique-object';
}

export function isObjectDir(resource: Dir) {
  return resource.directoryType === 'object-type';
}

export function isFile(resource: BaseResource): resource is FileResource {
  return resource && resource.type === RESOURCE_TYPE.FILE;
}

interface MapResourceArgs {
  dirs: Dir[];
  files: File[];
}

export function transformDirectoryToResource(dir: Dir): Resource {
  return {
    ...dir,
    type: RESOURCE_TYPE.DIRECTORY
  };
}

export function transformFileToResource(file: File): Resource {
  return {
    ...file,
    fileType: file.type,
    title: file.filename,
    type: RESOURCE_TYPE.FILE
  };
}

export function mapResources({ dirs, files }: MapResourceArgs) {
  return [
    // Directories
    ...Array.from(dirs || []).map(transformDirectoryToResource),
    // Files
    ...Array.from(files || []).map(transformFileToResource)
  ] as Resource[];
}

import React from 'react';
import { FieldProps } from './types';
import { FormControl, InputLabel } from '@material-ui/core';
import { Select } from '../../../../../../components';

const SelectCreatable: React.FC<FieldProps> = ({
  value,
  errorMessage,
  name,
  multiple,
  form,
  settings = {}
}) => {
  const [options, setOptions] = React.useState<any[]>(
    Array.from(settings.options || [])
  );

  const handleChange = (newValue: any, actionMeta: any) => {
    if (newValue && 'create-option' === actionMeta.action) {
      const newOption = newValue.filter((item: any) => item.__isNew__)[0];
      if (newOption) {
        setOptions(prev => [
          ...prev,
          {
            label: newOption.label,
            value: newOption.value
          }
        ]);
      }
    }

    let nextValue;
    if (newValue) {
      nextValue = multiple
        ? Array.from(newValue || []).map((item: any) => item.value)
        : newValue.value;
    } else {
      nextValue = multiple ? [] : null;
    }

    form.setFieldValue(name, nextValue, false);
  };

  const selectedValues = multiple
    ? Array.from(options || []).filter((option: any) => {
        return Array.isArray(value) && value.includes(option.value);
      })
    : Array.from(options || []).filter(option => option.value === value);

  const sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <FormControl fullWidth variant="outlined" error={!!errorMessage}>
      {errorMessage && <InputLabel>{errorMessage}</InputLabel>}
      <Select
        multiple={multiple}
        options={sortedOptions}
        value={selectedValues}
        onChange={handleChange}
        creatable
      />
    </FormControl>
  );
};

export { SelectCreatable };

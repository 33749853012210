import { shallowEqual, useSelector } from 'react-redux';
import {
  selectRootFiles,
  selectDirFiles,
  selectFilesFetching
} from '../selectors';
import { File } from '../index';
import { RootState } from '../../../store';
import { isDirFetching } from '../../dirs/selectors';

export function useFileList(dirId?: number) {
  const selector = dirId
    ? (state: RootState) => selectDirFiles(state, dirId)
    : selectRootFiles;

  const files: File[] = useSelector(selector, shallowEqual);
  const isFetching: boolean = useSelector(selectFilesFetching, shallowEqual);
  const isSingleDirFetching: boolean = useSelector(
    state => (dirId ? isDirFetching(state as RootState, dirId) : false),
    shallowEqual
  );

  return {
    files,
    isFetching: isFetching || isSingleDirFetching
  };
}

import { get } from 'lodash';

const initial = {
  filters: {},
  actions: {},
  plugins: {},
  store: null
};

const Quodoc = { ...initial } as any;

export function reset() {
  Quodoc.filters = {};
  Quodoc.actions = {};
  Quodoc.plugins = {};
  Quodoc.store = null;
}

export function addPlugin(id: string, factory: Function) {
  Quodoc.plugins[id] = factory;
}

export async function bootstrap({ store }: any) {
  reset();
  Quodoc.store = store;
  const pluginsRaw = get(process.env, 'REACT_APP_QUODOC_PLUGINS', null);

  if (pluginsRaw) {
    await Promise.all(
      pluginsRaw.split(',').map(async plugin => {
        const factory = await import(`../plugins/${plugin}`);

        if (typeof factory.default === 'undefined') {
          throw new Error(
            `Plugin ${plugin} does not exports default function. Please review the code.`
          );
        }

        addPlugin(plugin, factory.default);
      })
    );
  }

  Object.keys(Quodoc.plugins).map(id =>
    Quodoc.plugins[id].call(null, [Quodoc])
  );
}

export function addFilter(id: string, callback: any) {
  Quodoc.filters[id] = Quodoc.filters[id] || [];
  Quodoc.filters[id].push(callback);
}

export function applyFilters<T, Context = any>(
  id: string,
  value: T,
  context?: Context
): T {
  const filters = Quodoc.filters[id] || [];

  return filters.reduce((prev: T, current: any) => {
    return current(prev, context);
  }, value) as T | any;
}

import React from 'react';
import { Fab, Divider, Theme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { useUiModal } from '../../../../../modules/ui/hooks';
import { CREATE_DIRECTORY_MODAL_ID } from '../../../../../modules/ui';
import { AdminRole } from '../../../../../modules/auth';
import { applyFilters } from '../../../../../core';

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fab: {
    margin: theme.spacing(1.5)
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const NavBarActions: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('navigation');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const { show } = useUiModal();

  const handleCreateDirClick = () => {
    show(CREATE_DIRECTORY_MODAL_ID);
  };

  const handleNewUploadClick = () => {
    const input = document.getElementById('file-upload-input');
    if (input) {
      input.click();
    }
  };

  const defaultActions = [
    {
      id: 'New folder',
      icon: CreateNewFolderIcon,
      action: handleCreateDirClick
    },
    {
      id: 'New upload',
      icon: CloudUploadIcon,
      action: handleNewUploadClick
    }
  ];

  const actions = applyFilters('navbar_actions', defaultActions);

  return (
    <>
      <div className={classes.actions}>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          size="small"
          onClick={handleClick}>
          <AddIcon />
        </Fab>
        <Menu
          id="navbar-actions"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {actions.map(({ id, icon: Icon, action }) => (
            <MenuItem
              key={id}
              onClick={() => {
                action && action();
                handleClose();
              }}>
              <Icon className={classes.icon} />
              {t(id)}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <Divider />
    </>
  );
};

export default React.memo(() => (
  <AdminRole>
    <NavBarActions />
  </AdminRole>
));
